import HeroSection from "./components/HeroSection";

function App() {
  return (
    <div className="App">
      <HeroSection/>
    </div>
  );
}

export default App;
